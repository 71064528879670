// turn off bootstrap scroll behavior
@media (prefers-reduced-motion: no-preference) {
  :root {
    scroll-behavior: inherit;
  }
}
.scroll {
  scroll-behavior: smooth;
}

// Selection bg and color
::selection {
  color: $color-light;
  background: $color-primary;
}

a {
  text-decoration: none;
  color: $color-dark;
  transition: 0.3s;
}
body {
  box-sizing: border-box;
}

.section-wrapper {
  overflow-x: hidden;
}

// Backgrounds
.bg-one {
  background-color: $bg-one;
}
.bg-two {
  background-color: $bg-two;
}
// Section paddings
.section-padding {
  padding: 80px 0;
}

.section-padding-t {
  padding-top: 80px;
}

.section-padding-b {
  padding-bottom: 80px;
}

// badge text
.badge-text {
  text-transform: uppercase;
  letter-spacing: 4px;
  color: $color-primary;
  font-weight: 600;
  display: inline-block;
}
// Section title
[class*='section-title-'] {
  margin-bottom: 50px;
  span {
    text-transform: uppercase;
    letter-spacing: 4px;
    color: $color-primary;
    font-weight: 600;
    display: inline-block;
  }
  h2 {
    margin-top: 0px;
  }
}

.section-title-left {
  p {
    margin-left: 0;
  }
}

.section-title-center {
  p {
    margin: 0 auto;
  }
}

.section-divider {
  margin-top: 20px;
}

/* Traingle divider */

.divider-traingle {
  width: 200px;
  border-width: 1px;
  border-style: solid;
  border-color: $color-primary;
  opacity: 0.4;
  position: relative;
  display: inline-block;
}

.divider-traingle:before {
  position: absolute;
  content: '';
  width: 18px;
  height: 18px;
  background-color: $color-primary;
  top: -9px;
  opacity: 0.4;
  right: 50%;
  transform: rotate(45deg);
}

.divider-traingle:after {
  position: absolute;
  content: '';
  width: 18px;
  height: 18px;
  background: $color-primary;
  top: -9px;
  left: 45%;
  opacity: 0.4;
  transform: rotate(45deg);
}

.button {
  pointer-events: auto;
  cursor: pointer;
  background: $color-primary;
  color: $color-light;
  border: none;
  text-align: center;
  padding: 10px 30px;
  line-height: 30px;
  margin: 0;
  position: relative;
  display: inline-block;
  &:hover {
    color: $color-light;
  }
  &::before,
  &::after {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
  &__primary {
    border-radius: 3px;
    overflow: hidden;
    color: $color-light;
    background: $color-secondary;
    &::before {
      content: '';
      background: $color-primary;
      width: 125%;
      left: -12%;
      transform: skew(30deg);
      transition: transform 0.4s cubic-bezier(0.3, 1, 0.8, 1);
    }
    span {
      position: relative;
    }
    &:hover::before {
      transform: translate3d(100%, 0, 0);
    }
  }
}

.btn__secondary {
  position: relative;
  display: inline-block;
  line-height: 30px;
  text-align: center;
  padding: 10px 30px;
  border-radius: 3px;
  overflow: hidden;
  text-decoration: none;
  background-color: transparent;
  color: $color-primary;
  transition: all 0.3s ease;
  cursor: pointer;
  svg {
    font-size: 20px;
    margin-right: 5px;
  }

  &::before,
  &::after {
    content: '';
    position: absolute;
    width: 15px;
    height: 100%;
    border-width: 1px;
    border-style: solid;
    border-color: $color-primary;
    transition: all 0.5s ease;
  }
  &::before {
    top: 0px;
    left: 0px;
    border-right: none;
  }
  &::after {
    top: 0px;
    right: 0px;
    border-left: none;
  }
  &:hover::before,
  &:hover::after {
    width: 100%;
  }
  &:hover {
    text-decoration: none;
    background: $color-primary;
    color: $color-light;
    transition-delay: 0.35s;
  }
  &.btn-small {
    padding: 4px 20px;
  }
}

// links
.link {
  font-weight: 500;
  position: relative;
  color: $color-primary;
  transition: all 0.4s ease-in-out;
  display: flex;
  align-items: center;
  & svg {
    position: relative;
    margin-left: 4px;
    font-size: 20px;
    transition: all 0.4s ease-in-out;
  }
  &:hover {
    color: $color-secondary;
    & svg {
      margin-left: 6px;
    }
  }
}

// Social icon

.social-icon {
  padding-left: 0;
  display: flex;
  gap: 10px;
  align-items: center;
  margin: 0;
  padding: 0;
  li {
    list-style: none;
    a {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 40px;
      height: 40px;
      padding: 5px;
      background-color: $color-primary;
      color: $color-light;
      transition: all 0.5s;
      box-shadow: 3px 3px $color-secondary;
      &:hover {
        background-color: $color-secondary;
        box-shadow: none;
      }
      @media screen and (max-width: 320px) {
        width: 30px;
        height: 30px;
      }
      svg {
        font-size: 20px;
        @media screen and (max-width: 320px) {
          font-size: 15px;
        }
      }
    }
  }
}

// Preloader
#preLoader {
  position: fixed;
  height: 100vh;
  width: 100%;
  top: 0;
  left: 0;
  z-index: 999;
  animation: preLoader 1s 1s linear forwards;
  background-color: $color-light;
}

// Circle animations
.circle {
  width: 70px;
  height: 70px;
  border-radius: 50%;
  position: absolute;
  overflow: hidden;
  opacity: 0.3;
  border-width: 5px;
  border-style: solid;
  border-color: $color-tertiary;
}

.x1 {
  animation: movecircles 25s linear infinite;
  top: 150px;
}

.x2 {
  left: 200px;
  top: 200px;
  transform: scale(0.6);
  animation: movecircles 35s linear infinite;
}

.x3 {
  left: -250px;
  bottom: 250px;
  transform: scale(0.8);
  animation: movecircles 40s linear infinite;
}

.x4 {
  left: 470px;
  bottom: 300px;
  transform: scale(0.75);
  animation: movecircles 45s linear infinite;
}

.x5 {
  left: -150px;
  bottom: 100px;
  transform: scale(0.8);
  animation: movecircles 50s linear infinite;
}

.video-btn {
  border: none;
  height: 70px;
  width: 70px;
  line-height: 80px;
  text-align: center;
  color: $color-secondary;
  background-color: $color-light;
  font-size: 22px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  position: absolute;
  left: 50%;
  top: 50%;
  margin-left: -50px;
  margin-top: -50px;
  z-index: 99;
  padding-left: 5px;
  box-shadow: 0 0 30px $color-shadow;
  &:hover {
    color: $color-primary;
  }
}
.waves-block .waves {
  position: absolute;
  width: 100px;
  height: 100px;
  background: $color-light;
  opacity: 0;
  border-radius: 100%;
  animation: waves 4s ease-in-out infinite;
  left: 50%;
  top: 50%;
  margin-left: -65px;
  margin-top: -65px;
  z-index: 3;
}
.waves-block .wave-1 {
  animation-delay: 0s;
}
.waves-block .wave-2 {
  animation-delay: 1s;
}
.waves-block .wave-3 {
  animation-delay: 2s;
}
// Hover translate effect
.translateEffect1 {
  transition: all 0.3s;
  &:hover {
    transform: translateY(-10px);
  }
}
.translateEffect2 {
  transition: all 0.3s;
  &:hover {
    transform: translateY(-5px);
  }
}
