/* Basic styling for the events and sidebar */
html {
  scroll-behavior: smooth;
}

.section-padding {
  padding: 60px 0;
}

.styleguide-sidebar {
  padding: 0 20px 20px 20px;
  margin-bottom: 20px;
}

.styleguide-h1{
  font-size:4em;
  opacity: .45;
  margin-bottom: 3rem;
  margin-top:-15px;
}

.styleguide-container h4{
  margin-top:45px;
  margin:100px;
}

a.styleguide-sidebar-heading, ul.styleguide-nav li ul li {
  cursor:pointer
}

.styleguide-sidebar-heading {
  text-transform: uppercase;
  font-weight: 700;
}

ul.styleguide-nav{
  margin: 0;
  padding: 0;
  list-style-type: none;
}

ul.styleguide-nav li{
  margin-bottom: 15px;
}

ul.styleguide-nav li ul, ul.styleguide-nav li ul li {
  list-style-type: none;
  margin: 4px 4px 4px 0;
  padding: 0;
}

.sticky {
  position: fixed;
  top: 70px;
}

@media (max-width: 767px) {
  .sticky {
    position: static;
  }
}

/* Style Guide content styling */
.styleguide__example {
  border: 1px solid #ddd;
  padding: 25px;
  border-radius: 8px;
  background-color: #fff;
}

.styleguide-content section{
  padding-bottom: 45px;
  margin-bottom: 65px;
}

